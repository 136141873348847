import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../../styles/Profile.css';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
const Profile = ({ isOpen, onClose }) => { 
  const { user, logout } = useAuth();
  const [editing, setEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect((navigate) => {
    const fetchProfile = async () => {
      try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
          throw new Error('No token found');
        }

        const response = await axios.get(`${ApiEndpoints.BASE_URL}${ApiEndpoints.PROFILE_URL}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
      
        
        setEditedUser(response.data);
        setLoading(false);
      } catch (error) {
        if(error.status === 401){
          localStorage.clear();
          navigate('/login');
        }
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleEditToggle = () => {
    if (editing) {
      updateProfile();
    }
    setEditing(!editing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const updateProfile = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        throw new Error('No token found');
      }
  
      const response = await axios.put(`${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${editedUser.id}`, editedUser, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      setEditedUser((prev) => ({
        ...response.data,
        password: prev.password, 
      }));
  
      alert('Profile updated successfully');
    } catch (error) {
      setError(error.message);
    }
  };
  

  const handleLogout = () => {
    logout();
    onClose();
    navigate('/');  // Redirect to home page or login page after logout
  };

  // const handleBack = () => {
  //   if (editing) {
  //     setEditing(false);
  //   } else {
  //     onClose();
  //   }
  // };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={`profile-drawer ${isOpen ? 'open' : ''}`}>
      <div className="profile-container">
        <button className="btn close-btn" onClick={onClose}>X</button>
        <div className="profile-content">
          <h2 className="profile-heading">Profile</h2>
          <div className="user-info">
            {editing ? (
              <>
                <div className="input-group">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={editedUser.name || ''}
                    onChange={handleInputChange}
                    className="profile-input"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedUser.email || ''}
                    onChange={handleInputChange}
                    className="profile-input"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="role">Role:</label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    value={editedUser.role || ''}
                    onChange={handleInputChange}
                    className="profile-input"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="mobileNumber">Phone Number:</label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={editedUser.mobileNumber || ''}
                    onChange={handleInputChange}
                    className="profile-input"
                  />
                </div>

                {/* <div className="input-group">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={editedUser.password || ''}
                    onChange={handleInputChange}
                    className="profile-input"
                  />
                </div> */}
                <div className="btn-group">
                  <button className="btn save-btn" onClick={handleEditToggle}>Save</button>
                  <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
                </div>
              </>
            ) : (
              <>
                <div className="info-item">
                  <strong>Name:</strong> {editedUser?.name}
                </div>
                <div className="info-item">
                  <strong>Email:</strong> {editedUser?.email}
                </div>
                <div className="info-item">
                  <strong>Role:</strong> {editedUser?.role}
                </div>
                <div className="info-item">
                  <strong>Phone Number:</strong> {editedUser?.mobileNumber}
                </div>
                {/* <div className="info-item">
                  <strong>Password:</strong> {editedUser?.password}
                </div> */}
                <div className="btn-group">
                  <button className="btn edit-btn" onClick={handleEditToggle}>Edit</button>
                  <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

};

export default Profile;
