import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/Customer.css'; // Adjust path to CSS file as needed
import Navbar from '../Navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import GoogleMapComponent from '../../components/GoogleMap/GoogleMap';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState({});
  const [newCustomerData, setNewCustomerData] = useState({
    name: '',
    mobileNumber: '',
    address: '',
    email: '',
    password: '',
    role: 'CUSTOMER'
  });
  const [addressData, setAddressData] = useState({
    id: '',
    address: '',
  });




  const [formData, setFormData] = useState({
    doorNo: '',
    contactPerson: '',
    contactNumber: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    userId: 0,
    latitude: 0,
    longitude: 0,
    formattedAddress: '',
    placeId: '',
  });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '',
    size: '',
    totalElements: '',
    totalPages: '',
  });

  const handleLocationSelect = (location) => {
    setFormData(prevData => ({
      ...prevData,
      latitude: location.lat,
      longitude: location.lng,
      formattedAddress: location.formattedAddress,
      placeId: location.placeId
    }));
  };

  // Function to fetch customers
  const fetchCustomers = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token:', access_token);

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}vendor/list?page=${pagination.page}&size=${pagination.size}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data.content)) {
        setCustomers(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        });
      } else {
        console.error('Unexpected data format:', response.data);
        setCustomers([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Error fetching customers. Please try again later.');
      setLoading(false);
    }
  };

  // Fetch customers on component mount
  useEffect(() => {
    fetchCustomers();
  }, [pagination.page, pagination.size]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const handleEditClick = (customer) => {
    setEditCustomerData(customer);
    setShowEditPopup(true);
  };

  const handleEditCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      console.log('Access Token for Edit:', access_token);
      console.log('Edit Customer Data:', editCustomerData);

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}vendor/${editCustomerData.id}`,
        editCustomerData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedCustomers = customers.map(cust =>
          cust.id === editCustomerData.id ? editCustomerData : cust
        );
        setCustomers(updatedCustomers);
        setShowEditPopup(false);
        toast.success('Employee edited successfully!');
      } else {
        console.error('Failed to edit customer:', response);
      }
    } catch (error) {
      console.error('Error editing customer:', error);
      setError('Error editing customer. Please try again later.');
    }
  };


  const handleAddAddress = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token for Add:', access_token);
      console.log('New Customer Data:', newCustomerData);

      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };
      console.log('Request Headers:', headers);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}`,
        newCustomerData,
        { headers }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setShowAddPopup(false);
        toast.success('Address added successfully!');
        fetchCustomers();
      } else {
        console.error('Failed to add address:', response.data);
        toast.error('Failed to add customer. Please try again.');
      }
    } catch (error) {
      console.error('Error adding address:', error);
      setError('Error adding address. Please try again later.');
      toast.error('Error adding address. Please try again.');
    }
  };

  const handleAddAddressClick = (customer) => {
    setAddressData({
      id: customer.id,
      address: customer.address || '',
    });
    setShowAddAddressPopup(true);
  };

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        console.error('No token found');
        throw new Error('No token found');
      }

      console.log('Attempting to delete customer with ID:', customerToDelete.id);

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}${customerToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      console.log('Delete request response:', response);

      if (response.status === 200) {
        console.log('Customer deleted successfully:', customerToDelete.id);
        toast.success('Customer Deleted successfully!');
        const updatedCustomers = customers.filter(cust => cust.id !== customerToDelete.id);
        setCustomers(updatedCustomers);
      } else {
        console.error('Failed to delete customer:', response);
        throw new Error('Failed to delete customer');
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      setError('Error deleting customer. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleAddCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token for Add:', access_token);
      console.log('New Customer Data:', newCustomerData);

      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };
      console.log('Request Headers:', headers);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}vendor`,
        newCustomerData,
        { headers }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setShowAddPopup(false);
        toast.success('Customer added successfully!');
        fetchCustomers();
      } else {
        console.error('Failed to add customer:', response.data);
        toast.error('Failed to add customer. Please try again.');
      }
    } catch (error) {
      console.error('Error adding customer:', error);
      setError('Error adding customer. Please try again later.');
      toast.error('Error adding customer. Please try again.');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer && customer.name && customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCustomerClick = () => {
    setNewCustomerData({
      name: '',
      mobileNumber: '',
      Address: '',
      role: 'CUSTOMER'
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          />
          <h1>Vendor List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Add Vendor</button>
        </div>
        <div className="table-responsive">
          <table className="customer-list-table">
            <thead>
              <tr>
                <th>S.No</th> {/* Added header for serial number */}
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile number</th>
                {/* <th>Address</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer, index) => (
                <tr key={customer.id}>
                  <td data-label="S.No">{index + 1}</td> {/* Serial number column */}
                  <td data-label="ID">{customer.id}</td>
                  <td data-label="Name">{customer.name}</td>
                  <td data-label="Name">{customer.email}</td>
                  {/* <td data-label="Name">{customer.password}</td> */}
                  <td data-label="Mobile number">{customer.mobileNumber}</td>
                  {/* <td data-label="Address">{customer.address}</td> */}
                  <td data-label="Actions">
                    <div className="action-buttons-container">
                      <button className="edit-button19" onClick={() => handleEditClick(customer)}>Edit</button>
                      <button className="delete-button19" onClick={() => handleDeleteClick(customer)}>Delete</button>
                      <button className="add-address-button" onClick={() => handleAddAddressClick(customer)}>Add Address</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {/* Add Customer Popup */}
        {showAddPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Add Vendor</h2>
              <form onSubmit={handleAddCustomer}>

                {/* Name */}
                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={newCustomerData.name}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, name: e.target.value })}
                    required
                  />
                </div>

                {/* <div className="form-group-cust">
                  <label>Address:</label>
                  <textarea
                    value={newCustomerData.address}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, address: e.target.value })}
                    required
                  />
                </div> */}

                <div className="form-group-cust">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={newCustomerData.email}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, email: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Password:</label>
                  <input
                    type="password"
                    value={newCustomerData.password}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, password: e.target.value })}
                    required
                    pattern=".{8,}"
                    title="Password must be at least 8 characters long."
                  />
                </div>

                <div className="form-group-cust">
                  <label>Mobile Number</label>
                  <input
                    type="value"
                    value={newCustomerData.mobileNumber}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                  />
                </div>

                {/* Buttons */}
                <div className="button-container32">
                  <button type="submitss" className="save-button32">Add</button>
                  <button type="button" className="cancel-button32" onClick={handleClosePopup}>Cancel</button>
                  <button className="close-button32" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Edit Customer Popup */}
        {showEditPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Edit Customer</h2>
              <form onSubmit={handleEditCustomer}>

                {/* Name */}
                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={editCustomerData.name}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, name: e.target.value })}
                    required
                  />
                </div>


                <div className="form-group-cust">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={editCustomerData.email}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, email: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Mobile Number</label>
                  <input
                    type="value"
                    value={editCustomerData.mobileNumber}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                  />
                </div>


                {/* Buttons */}
                <div className="button-container32">
                  <button type="submitss" className="save-button32">Update</button>
                  <button type="button" className="cancel-button32" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button className="close-button32" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>

              </form>
            </div>
          </div>
        )}

        {showAddAddressPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Add Address</h2>
              <form onSubmit={handleAddAddress}>
                <div className="form-group-cust">
                  <label>Address:</label>
                  <textarea
                    value={addressData.address}
                    onChange={(e) => setAddressData({ ...addressData, address: e.target.value })}
                    required
                  />
                  <GoogleMapComponent onSelectLocation={handleLocationSelect} />

                </div>

                <div className="button-container32">
                  <button type="submits" className="save-button32">Save</button>
                  <button type="button" className="cancel-button32" onClick={() => setShowAddAddressPopup(false)}>Cancel</button>
                  <button className="close-button32" onClick={() => setShowAddAddressPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}



        {/* Delete Confirmation Modal */}
        {showModal && (
          <div className="modal-overlayb">
            <div className="modal-contentb">
              <p>Are you sure you want to delete the {customerToDelete.name}?</p>
              <div className="button-containerb">
                <button className='Confirm' onClick={handleConfirmDelete}>Yes</button>
                <button className='Cancel' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}

        <div className="pagination">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>

      </div>
    </div>
  );
};

export default CustomerList;
