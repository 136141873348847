import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/Navbar.css';
import Profile from '../Profile/Profile';
import routeConfig from '../../routeConfig';
import logo from '../../assetss/images/logo.jpg'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  // Determine the base route based on the current location
  const getHomeLink = () => {
    const path = location.pathname;
    // Find the longest matching prefix
    for (const route in routeConfig) {
      if (path.startsWith(route)) {
        return routeConfig[route];
      }
    }
    return '/'; // Fallback to home or default route
  };
  const handleLogout = () => {
    logout();
    navigate('/');  // Redirect to home page or login page after logout
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-left">
        <img src={logo} alt="Company Logo" className="navbar-logo" style={{ width: '50px', height: 'auto' }} />
        <span className="navbar-company-name">Vendor Login</span>
        </div>
        <div className="navbar-right">
          <Link to={getHomeLink()} className="navbar-link">Home</Link>
          <span onClick={toggleDrawer} className="navbar-link">Account</span> 
        </div>
        <div className="btn-group">
                  <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
                </div>
      </nav>
      <Profile isOpen={isDrawerOpen} onClose={toggleDrawer}/>
    </div>
  );
};

export default Navbar;
