import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/Orders.css'; // Adjust path to CSS file as needed
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import Navbar from '../Navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PosReceipt from '../../POSprint/POSprint';
import GoogleMapComponent from '../../components/GoogleMap/GoogleMap';


const OrderList = () => {
  const [orders, setorders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [singleDate, setSingleDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [manualMobileNumber, setManualMobileNumber] = useState('');
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [newOrderData, setnewOrderData] = useState({
    vendorId: localStorage.getItem('userid'),
    name: '',
    customerAddress: {
      address: '',
      userId: '',
      latitude: 0,
      longitude: 0,
      formattedAddress: '',
      placeId: '',
    },
    mobileNumber: '',
    orderAmount: '',
    paymentType: 'PREPAID',
    deliverySlotType: 'INSTANT'
  });


  // const [newCustomer, setNewCustomer] = useState({
  //   name: '',
  //   customerAddress: {
  //     address: '',
  //     userId: ''
  //   },
  //   mobileNumber: '',
  //   orderAmount: '',
  //   paymentType: ''
  // });

  const [addressFormData, setAddressFormData] = useState({
    address: '',
    userId: '',
    latitude: 0,
    longitude: 0,
    formattedAddress: '',
    placeId: '',
  });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '',
    size: '',
    totalElements: '',
    totalPages: '',
  });


  const fetchorders = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      let url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}/vendor/${localStorage.getItem('userid')}/list?page=${pagination.page}&size=${pagination.size}&filterMode=${filterType}`;
      if (filterType === "single") {
        url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}/vendor/${localStorage.getItem('userid')}/list?page=${pagination.page}&size=${pagination.size}&filterMode=${filterType}&date=${singleDate}`;
      }
      else if (filterType === "range") {
        url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}/vendor/${localStorage.getItem('userid')}/list?page=${pagination.page}&size=${pagination.size}&filterMode=${filterType}&startDate=${startDate}&endDate=${endDate}`;

      } else {
        url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}/vendor/${localStorage.getItem('userid')}/list?page=${pagination.page}&size=${pagination.size}&filterMode=${filterType}`;
      }
      const response = await axios.get(
        url, { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data.content)) {
        setorders(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        });
      } else {
        console.error('Unexpected data format:', response.data);
        setorders([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Error fetching orders. Please try again later.');
      setLoading(false);
    }
  };


  // Fetch orders on component mount
  useEffect(() => {
    fetchorders();
  }, [pagination.page, pagination.size]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };
  const handleManualInputChange = (value) => {
    if (value.trim() === '') {
      setManualMobileNumber('');
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: '',
        name: '',
        customerAddress: {
          address: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
          placeId: '',
        }
      }));
      setSelectedLocation({
        address: '',
        latitude: 11.1380481,
        longitude: 77.3249964,
        formattedAddress: '',
        placeId: '',
      });
    } else {
      // Update only the mobile number if input is provided
      setManualMobileNumber(value);
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: value,  // Update mobileNumber with manual input
      }));
    }
  };
  

  const handleCustomerSelection = (user) => {
    if (user) {
      // Update selected customer
      setSelectedCustomer(user);
  
      // Update new order data with customer details
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: user.mobileNumber || manualMobileNumber,
        name: user?.name ?? prevData.name,
        customerAddress: user?.customerAddress
          ? {
            ...prevData.customerAddress, // Preserve existing address details
            address: user.customerAddress.address,
            latitude: user.customerAddress.latitude ?? 0,
            longitude: user.customerAddress.longitude ?? 0,
            formattedAddress: user.customerAddress.formattedAddress, // Ensure formattedAddress is updated
            placeId: user.customerAddress.placeId || '', // Ensure placeId is updated
          }
          : {
            ...prevData.customerAddress, // Preserve existing address details
            address: '', // Clear address if no customer address is provided
            latitude: 0,
            longitude: 0,
            formattedAddress: '', // Ensure formattedAddress is cleared
            placeId: '', // Ensure placeId is cleared
          },
      }));
  
      // Update map location if necessary
      const updatedAddress = user?.customerAddress
        ? {
          address: user.customerAddress.address,
          latitude: user.customerAddress.latitude ?? '',
          longitude: user.customerAddress.longitude ?? '',
          formattedAddress: user.customerAddress.formattedAddress,
          placeId: user.customerAddress.placeId || '',
        }
        : {
          address: '',
          latitude: '',
          longitude: '',
          formattedAddress: '',
          placeId: '',
        };
  
      setSelectedLocation(updatedAddress);
  
      // Debugging: log the selected customer ID
      console.log('Selected Customer ID:', user.id);
      console.log('Selected Customer ID:', user.customerAddress);
    } else {
      // If no customer is selected, clear all related fields
      setSelectedCustomer(null);
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: '',
        name: '',
        customerAddress: {
          address: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
          placeId: '',
        }
      }));
      setSelectedLocation({
        address: '',
        latitude: 0,
        longitude: 0,
        formattedAddress: '',
        placeId: '',
      });
    }
  };
  


  const handleAddressSelection = (event) => {
    const selectedAddressId = event.target.value;

    if (selectedAddressId) {
      // Update the new order data state with the selected customer address ID
      setnewOrderData(prevData => ({
        ...prevData,
        customerAddressId: selectedAddressId
      }));
    }
  };


  // const handleLocationSelect = (location) => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     latitude: location.lat,
  //     longitude: location.lng,
  //     formattedAddress: location.formattedAddress,
  //     placeId: location.placeId
  //   }));
  // };

  const handleFilterButtonClick = () => {
    setFilterVisible(prevVisible => !prevVisible);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleSingleDateChange = (event) => {
    setSingleDate(event.target.value);
  };

  useEffect(() => {
    fetchorders();
  }, [singleDate]);

  useEffect(() => {
    fetchorders();
  }, [endDate]);

  useEffect(() => {
    fetchorders();
  }, [filterType]);



  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    fetchorders();
  };


  const handleAddOrder = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}`,
        newOrderData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSelectedCustomer(null);
        // Reset the states to their initial values
        setnewOrderData({
          vendorId: localStorage.getItem('userid'),
          name: '',
          customerAddress: {
            address: '',
            userId: ''
          },
          mobileNumber: '',
          orderAmount: '',
          paymentType: 'PREPAID',
          deliverySlotType: 'INSTANT'
        });

        setManualMobileNumber('');
        setShowAddPopup(false);

        toast.success('Order added successfully!');
        fetchorders();
      } else {
        console.error('Failed to add order:', response);
      }
    } catch (error) {
      console.error('Error adding order:', error);
      setError('Error adding order. Please try again later.');
    }
  };


  // const handleNewCustomerAddress = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const access_token = localStorage.getItem('token');
  //     if (!access_token) {
  //       throw new Error('No token found');
  //     }

  //     const response = await axios.post(
  //       `${ApiEndpoints.BASE_URL}${ApiEndpoints.VENDOR_ADDRESS}`,
  //       newCustomer,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setShowAddPopup(false);
  //       toast.success('Order added successfully!');
  //       fetchorders();
  //     } else {
  //       console.error('Failed to add order:', response);
  //     }
  //   } catch (error) {
  //     console.error('Error adding order:', error);
  //     setError('Error adding order. Please try again later.');
  //   }
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredorders = orders.filter(
    (order) =>
      order && order.customer && order.customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCustomerClick = () => {
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedCustomer(null);
    // Reset the states to their initial values
    setnewOrderData({
      vendorId: localStorage.getItem('userid'),
      name: '',
      customerAddress: {
      address: '',
      userId: '',
      latitude: 0,
      longitude: 0,
      formattedAddress: '',
      placeId: '',
    },
      mobileNumber: '',
      orderAmount: '',
      paymentType: 'PREPAID',
      deliverySlotType: 'INSTANT'
    });
    setSelectedLocation(null);
    setManualMobileNumber('');

    // Close the popup
    setShowAddPopup(false);
  };


  // const handlecustomerselection = (user) => {
  //   setselectedOrder(user);
  // };



  function formatDate(dateString) {
    const date = new Date(dateString);

    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const year = date.getFullYear().toString().slice(-2);
    const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${day} ${month} ${year} | ${time}`;
  }

  // const handleCustomerNameChange = (e) => {
  //   setNewCustomer(e.target.value);
  // };

  const handleLocationSelect = (location) => {
    setAddressFormData(prevData => ({
      ...prevData,
      latitude: location.latitude,
      longitude: location.longitude,
      formattedAddress: location.formattedAddress,
      placeId: location.placeId,
      address: location.formattedAddress
    }));
    setnewOrderData(prevData => ({
      ...prevData,
      customerAddress: {
        ...prevData.customerAddress, // spread existing customerAddress properties
        address: location.formattedAddress,
        latitude: location.latitude,
        longitude: location.longitude,
        formattedAddress: location.formattedAddress,
        placeId: location.placeId,
      }
    }));


    console.log(addressFormData);
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API
    console.log('Address Data:', addressFormData);
    setShowAddAddressPopup(false);
  };

  const handleAddAdressInputChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleMapClick = (locationData) => {
    const { lat, lng, formattedAddress, placeId } = locationData;

    setAddressFormData(prevData => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
      formattedAddress,
      placeId
    }));
  };



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          />
          <div>
            <button
              className={`filter-button ${filterVisible ? 'hide-filter-button' : ''}`}
              onClick={handleFilterButtonClick}
            >
              {filterVisible ? 'Hide Filter Options' : 'Show Filter Options'}
            </button>

            {filterVisible && (
              <div>
                <h2>Date Filter</h2>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="all"
                      checked={filterType === 'all'}
                      onChange={handleFilterTypeChange}
                    />
                    All
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="single"
                      checked={filterType === 'single'}
                      onChange={handleFilterTypeChange}
                    />
                    Single Date
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="range"
                      checked={filterType === 'range'}
                      onChange={handleFilterTypeChange}
                    />
                    Date Range
                  </label>
                </div>

                {filterType === 'single' && (
                  <div>
                    <label>
                      Select Date:
                      <input
                        type="date"
                        value={singleDate}
                        onChange={handleSingleDateChange}
                      />
                    </label>
                  </div>
                )}

                {filterType === 'range' && (
                  <div>
                    <label>
                      Start Date:
                      <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </label>
                    <label>
                      End Date:
                      <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </label>
                  </div>
                )}

                {/* {filterType === 'all' && (
                  <div>
                    <p>All dates are selected. No specific filter applied.</p>
                  </div>
                )} */}
              </div>
            )}
          </div>


          <h1>Order List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Create Order</button>
        </div>
        <div className="table-responsive">
          <table className="customer-list-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Mobile Number</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>PaymentType</th>
                <th>Order Status</th>
                <th>Print Receipt</th>
              </tr>
            </thead>
            <tbody>
              {filteredorders.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order.id}</td>
                  <td>{order.customer.name}</td>
                  <td>{order.customer.mobileNumber}</td>
                  {/* <td>{order.customerAddress.contactNumber}</td> */}
                  <td>{order.orderAmount}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{order.paymentType}</td>
                  <td>{order.status}</td>

                  <td>
                    <PosReceipt
                      customerAddress={order.customerAddress?.address || ''}
                      customerPhone={order.customer?.mobileNumber || ''}
                      orderId={order.id}
                      customerName={order.customer?.name || ''}
                      paymentType={order.paymentType || ''}
                      totalAmount={order.orderAmount ? `${order.orderAmount.toFixed(2)}` : '₹0.00'}
                      thankYouMessage="Thank You For Your Purchase!"
                      footerMessage="Feedback Appreciated"
                      storeNumber="8110995777"
                      upiId={localStorage.getItem('upi') ?? 'zeliver@ybl'}

                    />
                  </td>



                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {showAddPopup && (
  <div className="customer-popup">
    <div className="customer-popup-content">
      <h2>Create Order</h2>
      <form onSubmit={handleAddOrder}>
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '13px' }}>Mobile Number:</label>
            <div style={{ flex: 1 }}>
              <UserSearchDropdown
                onUserSelect={handleCustomerSelection}
                userName={selectedCustomer?.mobileNumber || manualMobileNumber}
                onManualInputChange={handleManualInputChange}
                required // Ensure this field is marked as required in UserSearchDropdown component
              />
            </div>
          </div>

          <div className="input-group1">
            <label>Customer Name:</label>
            <input
              type="text"
              className="line-input1"
              value={newOrderData.name}
              onChange={(e) => setnewOrderData({ ...newOrderData, name: e.target.value })}
              required
            />
          </div>

          <div className="form-container" style={{ marginTop: '-5px' }}>
            <label>Delivery Location:</label>
            <div className="google-map-container">
              <GoogleMapComponent
                onSelectLocation={handleLocationSelect}
                selectedLocation={selectedLocation ?? ''}
                required // Ensure that this field is marked as required in GoogleMapComponent
              />
            </div>
          </div>
        </>

        <div className="input-group1 order">
          <label>Mode Of Payment:</label>
          <div className="order-input-group">
            <label>
              <input
                type="radio"
                name="paymentType"
                value="PREPAID"
                checked={newOrderData.paymentType === 'PREPAID'}
                onChange={(e) => setnewOrderData({ ...newOrderData, paymentType: e.target.value })}
                className="order-input"
                required
              />
              Prepaid
            </label>
            <label>
              <input
                type="radio"
                name="paymentType"
                value="POD"
                checked={newOrderData.paymentType === 'POD'}
                onChange={(e) => setnewOrderData({ ...newOrderData, paymentType: e.target.value })}
                className="order-input"
                required
              />
              POD
            </label>
          </div>
        </div>

        {newOrderData.paymentType === 'POD' && (
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '4px', marginTop: '-30px' }}>
            <label style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>Order Amount:</label>
            <input
              type="number"
              value={newOrderData.orderAmount}
              onChange={(e) => setnewOrderData({ ...newOrderData, orderAmount: e.target.value })}
              required={newOrderData.paymentType === 'POD'} // Required only if POD is selected
              style={{ marginLeft: '13px', border: 'none', borderBottom: '1px solid #000', outline: 'none', width: '250px' }}
            />
          </div>
        )}

        {/* Buttons */}
        <div className="button-container">
          <button type="submit" className="save-button">Create Order</button>
          <button type="button" className="close-button" onClick={handleClosePopup}>Close</button>
        </div>
      </form>
    </div>
  </div>
)}


        <div className="pagination">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>

      </div>
    </div>
  );
};

export default OrderList;
